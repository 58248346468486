/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from 'theme-ui';
import slugify from 'slugify';
import { graphql, Link } from 'gatsby';
import { Box, Card, Flex, AspectImage, Text, Button, Image } from 'theme-ui';
import img from '../assets/hero.jpg';
import giftCardImage from '../assets/gc-white.png';
import SplashBlock from '../components/SplashBlock';
import Slide from 'react-reveal/Slide';

import Hero from '../components/Hero';
import Layout from '../components/Layout';
import MobileSplash from '../components/MobileSplash';
import ShoutCard from '../components/ShoutCard';
import DesktopBusinessShowcase from '../components/DesktopBusinessShowcase';
import MapPins from '../components/MapPins';
import PopularCategories from '../components/PopularCategories';
import LatestEvents from '../components/LatestEvents';
import NorwalkTogoBanner from '../components/NorwalkTogoBanner';
import SplitBoxes from '../components/SplitBoxes';
import CardComponent from '../components/Card';
import cloudinaryOptimize from '../helpers/cloudinaryHelper';

const Index = ({ data }) => {
  const events = data.events ? data.events.data.items : [];

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js';
    script.async = true;

    document.body.appendChild(script);

    const scriptVar = document.createElement('script');
    scriptVar.innerHTML = `var _ctct_m = "42867e8cd797cdb7673d5ae6b9483924"; `;
    //change

    const constantContactScript = document.createElement('script');

    constantContactScript.src =
      '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js';
    constantContactScript.async = true;
    constantContactScript.defer = true;

    document.body.appendChild(scriptVar);
    document.body.appendChild(constantContactScript);

    return () => {
      document.body.removeChild(script);
      // document.body.removeChild(constantContactScript);
    };
  });
  const PlacesImg = cloudinaryOptimize(
    'https://res.cloudinary.com/gonation/image/upload/v1701385654/sites/OnWashington/places-new.jpg',
    2000
  );

  const spacesImg = cloudinaryOptimize(
    'https://res.cloudinary.com/gonation/image/upload/v1701385654/sites/OnWashington/spaces-new.jpg',
    2000
  );

  const cardContent = [
    {
      img: PlacesImg,
      title: 'Places',
      subtitle: 'View businesses OnWashington Street',
      link: '/places',
    },
    {
      img: spacesImg,
      title: 'Spaces',
      subtitle: 'Open your business OnWashington Street',
      link: 'https://agwpartners.com/availabilities/',
    },
  ];

  const renderPlacesSpacesCards = () => (
    <Flex
      sx={{
        maxWidth: '1600px',
        margin: 'auto',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        padding: [2, 2, 'unset'],
      }}
    >
      {cardContent.map((content, idx) => (
        <Box
          sx={{
            flex: '1 1 300px',
            paddingX: [2, 3, 4],
            paddingRight: idx === 0 ? [0, '16px!important'] : 'unset',
            paddingLeft: idx === 1 ? [0, '16px!important'] : 'unset',
            mb: [3, 4, 0],
          }}
        >
          <Slide left={idx === 0} right={idx === 1}>
            <CardComponent data={content} />
          </Slide>
        </Box>
      ))}
    </Flex>
  );

  const renderPlacesParallax = () => (
    <Box>
      {cardContent.map((content, idx) => (
        <>
          <Box
            sx={{
              width: '100%',
              height: ['500px', '600px', '700px'],
              background: `url(${content.img})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundAttachment: 'fixed',
              display: 'flex',
              // justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                padding: [2, 3],
                paddingLeft: [3, 1, 7],
                maxWidth: '1200px',
              }}
            >
              <Text
                as="h4"
                variant="heading"
                sx={{
                  display: 'inline-block',
                  color: 'white',
                  borderBottom: '3px solid',
                  borderColor: 'primary',
                  mb: 4,
                }}
              >
                {content.title}
              </Text>
              <Text as="p" sx={{ color: 'white', fontSize: [2, 3, 4] }}>
                {content.subtitle}
              </Text>

              <Button
                variant="primary"
                sx={{
                  mt: [3, 3],
                  border: '2px solid',
                  borderColor: 'primary',
                  paddingX: 4,
                  paddingY: 3,
                  textTransform: 'uppercase',
                  fontSize: 3,
                  fontWeight: '500',
                  borderRadius: '3px',
                  cursor: 'pointer',
                  color: 'white',
                  textDecoration: 'none',
                  bg: 'primary',
                  '&:hover': {
                    borderColor: 'primary',
                    transition: 'all .3s',
                  },
                }}
              >
                <Link to={content.link}>Learn More</Link>
              </Button>
            </Box>
          </Box>
        </>
      ))}
    </Box>
  );

  return (
    <Layout pageTitle="Home">
      {/* <Hero img={img} /> */}
      <Box sx={{ display: ['block', 'none', 'none'] }}>
        <MobileSplash />
      </Box>
      <Box sx={{ display: ['none', 'block', 'block'] }}>
        <SplashBlock />
      </Box>

      <Box
        sx={{
          display: ['block', 'none', 'none'],
          paddingY: 4,
          paddingX: 3,
        }}
      >
        <ShoutCard bizID="bzn-PKzc8iPtRTSo0pG0hel46g" isHome />
      </Box>

      <Box>
        <DesktopBusinessShowcase
          businesses={data.businesses.items.filter(
            el => el.name !== 'GoNation Area 52'
          )}
        />
      </Box>

      <Box
        sx={{
          //   paddingY: [3, 3],0
          paddingTop: '0!important',
          borderTop: '2px solid black',
        }}
      >
        {renderPlacesParallax()}
      </Box>

      <Box
        sx={{
          paddingY: [4, 6],
          borderTop: '2px solid black',
          backgroundColor: 'black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            background: 'black',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              //   paddingLeft: [3, 1, 7],
              maxWidth: '1200px',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            {/* <Image
              sx={{ maxWidth: 400, margin: 'auto' }}
              src={giftCardImage}
            ></Image> */}
            <Text
              as="h4"
              variant="heading"
              sx={{
                display: 'inline-block',
                color: 'white',
                borderBottom: '3px solid',
                borderColor: 'primary',
                mb: 4,
              }}
            >
              PURCHASE AN ONWASHINGTON GIFT CARD
            </Text>
            <Text as="p" sx={{ color: 'white', fontSize: [2, 3, 4] }}>
              Send a Digital OnWashington Gift Card that can be used at any
              participating OnWashington business. Restaurants, shops, salons,
              and more! Great things are always happening OnWashington.
            </Text>

            <Button
              variant="primary"
              sx={{
                mt: [3, 3],
                border: '2px solid',
                borderColor: 'primary',
                paddingX: 4,
                paddingY: 3,
                textTransform: 'uppercase',
                fontSize: 3,
                fontWeight: '500',
                borderRadius: '3px',
                cursor: 'pointer',
                color: 'white',
                textDecoration: 'none',
                bg: 'primary',
                '&:hover': {
                  borderColor: 'primary',
                  transition: 'all .3s',
                },
              }}
            >
              <Link
                to={
                  'https://app.yiftee.com/gifts/egift_and_gift_cards/onwashington'
                }
              >
                Purchase Gift Cards
              </Link>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          paddingY: [3, 3],
          paddingBottom: '0px!important',
          textAlign: 'center',
        }}
      >
        <Text
          variant="heading"
          as="h1"
          sx={{
            color: 'text',
            fontSize: [4, 5],
            marginBottom: [2, 4],
            paddingX: 2,
            pb: 0,
            textTransform: 'none',
          }}
        >
          Be Social @OnWashingtonST
        </Text>
        {/* <iframe
          src='https://cdn.lightwidget.com/widgets/55e859ead2e6597b9b00892ba4b4c56a.html'
          scrolling='no'
          allowtransparency='true'
          class='lightwidget-widget'
          style={{ width: '100%', border: '0', overflow: 'hidden' }}></iframe> */}
        <iframe
          src="https://cdn.lightwidget.com/widgets/55e859ead2e6597b9b00892ba4b4c56a.html"
          scrolling="no"
          allowtransparency="true"
          class="lightwidget-widget"
          style={{
            width: '100%',
            border: '0',
            overflow: 'hidden',
            paddingBottom: 0,
          }}
        ></iframe>
      </Box>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  {
    businesses {
      items {
        avatar
        categories
        categoryGroups {
          name
        }
        contact {
          facebook
          instagram
          phone
          url
        }
        cover
        description
        gonationId
        location {
          address {
            city
            country
            postalCode
            state
            street
          }
          coordinates {
            lat
            lng
          }
        }
        name
        slug
      }
    }

    # events {
    #   data {
    #     items {
    #       gonationObject {
    #         business {
    #           cover
    #           avatar
    #           gonationId
    #           name
    #         }
    #         startAt
    #         name
    #         image
    #         gonationId
    #       }
    #     }
    #   }
    # }
  }
`;
